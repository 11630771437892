/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Modal from "react-modal"
import { CSSTransition } from "react-transition-group"

import { Icon } from "@iconify/react"
import phoneHandsetSolid from "@iconify/icons-clarity/phone-handset-solid"
import * as headerStyles from "./header.module.css"
import logo from "../images/logo.svg"

export default function Header() {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [testState, setTestState] = useState(false)

  function openModal() {
    setIsOpen(true)
    setTestState(true)
    document.getElementsByClassName(headerStyles.container)[0].style.zIndex =
      "51"
  }

  function closeModal() {
    setIsOpen(false)
    setTestState(false)
    document.getElementsByClassName(headerStyles.container)[0].style.zIndex =
      "49"
  }

  Modal.setAppElement("body")

  return (
    <div className={headerStyles.container}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={headerStyles.modalContainer}
        overlayClassName={headerStyles.modalOverlay}
        contentLabel="Modal Menu"
        closeTimeoutMS={300}
      >
        <ModalMenu closeModal={closeModal} modalIsOpen={modalIsOpen} />
      </Modal>
      <div className={headerStyles.iconContainer}>
        {!modalIsOpen ? (
          <BurgerMenu onClick={openModal} />
        ) : (
          <CloseButton onClick={closeModal} />
        )}
        <div className={headerStyles.logo}>
          <Link to="/">
            <img
              className={headerStyles.logoImg}
              src={logo}
              alt="Tintex"
              draggable={false}
            />
          </Link>
        </div>
        <div className={headerStyles.headerContact}>
          <a href="tel:+447479555325">
            <p className={headerStyles.headerContactNumber}>
              +44 (0) 7479 555325
            </p>
          </a>
          <div className={headerStyles.headerContactIcon}>
            <a href="tel:+447479555325">
              <Icon
                title="Phone"
                icon={phoneHandsetSolid}
                className={headerStyles.phoneIcon}
                aria-hidden
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

class BurgerMenu extends React.Component {
  render() {
    return (
      <div className={headerStyles.burgerMenu} onClick={this.props.onClick}>
        <div className={headerStyles.burgerLineShort} />
        <div className={headerStyles.burgerLineLong} />
        <div className={headerStyles.burgerLineShort} />
        <span className={headerStyles.burgerMenuText}>MENU</span>
      </div>
    )
  }
}

class CloseButton extends React.Component {
  render() {
    return (
      <svg
        id={headerStyles.modalMenuExitBtn}
        title="Close"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        xmlns="http://www.w3.org/2000/svg"
        onClick={this.props.onClick}
      >
        <rect
          width="28.3514"
          height="2.53064"
          transform="matrix(0.702868 0.71132 -0.72002 0.693953 1.85437 0.212311)"
        />
        <rect
          width="28.3514"
          height="2.53064"
          transform="matrix(-0.702868 0.71132 -0.72002 -0.693953 22.0322 1.89151)"
        />
      </svg>
    )
  }
}

class ModalMenu extends React.Component {
  componentDidMount() {
    const menuItems = document.getElementsByClassName(
      headerStyles.modalMenuItem
    )
    const menuItemsArray = Array.prototype.slice.call(menuItems)

    menuItemsArray.forEach(menuItem => {
      menuItem.addEventListener("click", () => {
        this.props.closeModal()
      })
    })
  }

  render() {
    return (
      <div id={headerStyles.modalBackground} style={this.props.style}>
        <div id={headerStyles.modal}>
          <div id={headerStyles.modalMenu}>
            <Link
              to="/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              HOME
            </Link>
            <Link
              to="/about/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              ABOUT US
            </Link>
            <Link
              to="/services/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              SERVICES
            </Link>
            <Link
              to="/portfolio/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              PORTFOLIO
            </Link>
            <Link
              to="/reviews/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              REVIEWS
            </Link>
            <Link
              to="/contact/"
              className={headerStyles.modalMenuItem}
              activeClassName={headerStyles.activeLink}
            >
              CONTACT US
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
