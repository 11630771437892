// extracted by mini-css-extract-plugin
export var activeLink = "header-module--activeLink--fEy75";
export var burgerLineLong = "header-module--burgerLineLong--fjXgc";
export var burgerLineShort = "header-module--burgerLineShort--O-u+j";
export var burgerMenu = "header-module--burgerMenu--v+ftH";
export var burgerMenuText = "header-module--burgerMenuText--MiLo6";
export var container = "header-module--container--XLnQW";
export var headerContact = "header-module--headerContact--aIuFW";
export var headerContactIcon = "header-module--headerContactIcon--dg3Nz";
export var headerContactNumber = "header-module--headerContactNumber--6ySYq";
export var iconContainer = "header-module--iconContainer--vp81X";
export var logo = "header-module--logo--mTLiM";
export var logoImg = "header-module--logoImg--0hRaT";
export var modal = "header-module--modal--Ircan";
export var modalBackground = "header-module--modalBackground--r8++B";
export var modalContainer = "header-module--modalContainer--b6o6h";
export var modalIconContainer = "header-module--modalIconContainer--kS+4Y";
export var modalLogo = "header-module--modalLogo--drdUc";
export var modalMenu = "header-module--modalMenu--5dO9-";
export var modalMenuExitBtn = "header-module--modalMenuExitBtn--CTFml";
export var modalMenuItem = "header-module--modalMenuItem--6n585";
export var modalOverlay = "header-module--modalOverlay--dAdii";
export var modalabout = "header-module--modalabout--GQOUg";
export var modalcareers = "header-module--modalcareers--3uyt+";
export var modalcompetitions = "header-module--modalcompetitions--93JkC";
export var modalhome = "header-module--modalhome--gQhmU";
export var modalhowitworks = "header-module--modalhowitworks--vcnWS";
export var modalwinners = "header-module--modalwinners--vnRvM";
export var phoneIcon = "header-module--phoneIcon--YLWbW";
export var phoneMove = "header-module--phoneMove--iZnJl";
export var rotate = "header-module--rotate--WXsJy";
export var shorten = "header-module--shorten--Lbg3Z";
export var typewriter = "header-module--typewriter--UNHqw";
export var widen = "header-module--widen--kDBfz";